import Splide from '@splidejs/splide'
import '@splidejs/splide/css'

class SplideCarousel extends HTMLElement {
  connectedCallback() {
    if (this.once) {
      return
    }
    this.once = true

    this.splide = new Splide(this)

    this.splide.on('moved', (index) => {
      this.dispatchEvent(
        new CustomEvent('splideMoved', { bubbles: true, detail: { index } }),
      )
    })

    this.splide.mount()

    if (this.dataset.rOptions.length !== 0) {
      this.splide.options = { ...JSON.parse(this.dataset.rOptions) }
    }
  }
}

customElements.define('splide-carousel', SplideCarousel)
